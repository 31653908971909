import { ERROR_MESSAGES } from '@/constants/error-messages';
import axios from 'axios';
import Vue from 'vue';
Vue.prototype.$axios = axios;

const baseURL = `${process.env.VUE_APP_API}/api/v2`;

const axiosClient = axios.create({
  baseURL,
});

const axiosClientAuth = axios.create({
  baseURL,
});

// Token refresh function
async function refreshAccessToken() {
  try {
    const refreshToken = localStorage.getItem('refresh_token');
    const accessToken = sessionStorage.getItem('token');
    const response = await axios.post(
      `${baseURL}/refresh-token`,
        { refresh_token: refreshToken },
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }
      );
    if (response) {
      const newAccessToken = response.data.data.token;
      const newRefreshToken = response.data.data.refresh_token;
      sessionStorage.setItem('token', newAccessToken);
      sessionStorage.setItem('refresh_token', newRefreshToken);
      localStorage.setItem('token', newAccessToken);
      localStorage.setItem('refresh_token', newRefreshToken);
      axiosClientAuth.defaults.headers['Authorization'] = `Bearer ${newAccessToken}`;
      return newAccessToken;
    }
  } catch (error) {
    console.error('Unable to refresh token v2', error);
    throw error;
  }
}

// Add a auth request interceptor
axiosClientAuth.interceptors.request.use(
  function (config) {
    const accessToken = localStorage.getItem('token');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a request interceptor
axiosClient.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    Vue.$toast.error(error?.response?.data?.message, {
      position: 'bottom-left',
    });
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosClientAuth.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newAccessToken = await refreshAccessToken();
        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
        return axiosClientAuth(originalRequest); // Re-make the request with the new token
      } catch (refreshError) {
        console.error('Need to login again v2');
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("refresh_token");
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        // Redirect users to the login page if needed
      }
    }

    if (error?.response?.data?.error_code !== "error_106") {
      Vue.$toast.error(ERROR_MESSAGES[error.response.data.error_code], {
        position: 'bottom-left',
      });
    }
    return Promise.reject(error);
  }
);

function httpGet(url) {
  return axiosClient.get(url);
}

function httpPost(url, data) {
  return axiosClient.post(url, data);
}

function httpPut(url, data) {
  return axiosClient.put(url, data);
}

function httpPatch(url, data) {
  return axiosClient.patch(url, data);
}

function httpDelete(url) {
  return axiosClient.delete(url);
}

function httpGetAuth(url, params = {}) {
  return axiosClientAuth.get(url, { params });
}

function httpPostAuth(url, data) {
  return axiosClientAuth.post(url, data);
}

function httpPutAuth(url, data) {
  return axiosClientAuth.put(url, data);
}

function httpPatchAuth(url, data) {
  return axiosClientAuth.patch(url, data);
}

function httpDeleteAuth(url) {
  return axiosClientAuth.delete(url);
}

export {
  baseURL,
  httpGet,
  httpPost,
  httpPut,
  httpDelete,
  httpPatch,
  httpGetAuth,
  httpPostAuth,
  httpPutAuth,
  httpPatchAuth,
  httpDeleteAuth,
};
